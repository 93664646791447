import React from 'react';

import Layout from '../global/Layout';

const NotFoundPage = () => (
  <Layout>
    <div className="m-mx-15 s-mx-7">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
